import axios from '@/common/axios'
import qs from 'qs'

export function campExamine (data) {
  return axios({
    method: 'put',
    url: '/camp/audit',
    data: qs.stringify(data)
  })
}

export function selectCampInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}

export function selectCampList (query) {
  return axios({
    method: 'get',
    url: '/camp/list',
    params: query
  })
}





