<template>
  <div id="camp">
    <el-dialog
      :title="campFormTitle"
      width="360px"
      :visible.sync="campDialogVisible"
      :close-on-click-modal="false"
      @close="campDialogClose"
    >
    
      <el-form
        ref="campFormRef"
        :model="campForm"
        :rules="campFormRules"
        label-position="right"
        label-width="100px"
      >
      <el-form-item label="营地名称" prop="name">
        <el-input v-model="campForm.name" placeholder="请输入营地名称" clearable />
      </el-form-item>
      <el-form-item label="营地类型" prop="type">
        <el-input v-model="campForm.type" placeholder="请输入营地名称" clearable />
      </el-form-item>
      <el-form-item label="审核状态" prop="status">
          <el-select v-model="campForm.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      </el-form>
      
      <div slot="footer">
        <el-button @click="campDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="campFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-row :gutter="20">
      <el-form inline size="small">
        <el-form-item label="审核状态选择" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择审核状态">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              搜索
            </el-button>
          </el-form-item>
      </el-form>
    
    <el-table
      ref="campTableRef"
      :data="campPage.list"
      :tree-props="{children: 'children'}"
      row-key="id"
      default-expand-all
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
      @row-click="handleRowClick"
    >
    
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="adress" label="地址" />
      <el-table-column prop="status" label="状态" />
      <el-table-column label="操作" align="center">
      <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEPT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="campExamine(scope.$index, scope.row)"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
      
    </el-table>
    <el-pagination
          :total="campPage.total"
          :current-page="searchForm.pageNum"
          :page-size="searchForm.pageSize"
          :page-sizes="[10, 15, 20]"
          layout="total, sizes, prev, pager, next, jumper"
          background
          @current-change="pageNumChange"
          @size-change="pageSizeChange"
        />
      </el-row>
  </div>
</template>

<script>
import {
  selectCampList,
  selectDeptInfo,
  selectUserList,
  selectCampInfo,
  campExamine
} from '@/api/business/examine'

export default {
  data () {
    return {
      campDialogVisible: false,
      campFormTitle: '',
      campList: [],
      statusList:[],
      campForm: {
        id: '',
        status: '',
        name:'',
        type:''
      },
    campPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1 ,
        pageSize: 10,
        id: '',
        status: '',
        name: '',
        type:''
      },
      options: [{
          value: '1',
          label: '通过'
        }, {
          value: '-1',
          label: '未通过'
        }],
      options1: [{
          value: '1',
          label: '通过'
        }, {
          value: '0',
          label: '未审核'
        }, {
          value: '-1',
          label: '未通过'
        }],
        value: '',
        campTree: [],
      campFormRules: {
        status: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      }
    }
  },
  created () {
    selectCampList(this.searchForm).then(res => {
      this.campPage = res
    })
    selectCampTree().then(res => {
      this.campTree = res
    })
  },
  methods: {
    campDialogClose () {
      this.$refs.campFormRef.resetFields()
    },
    campFormSubmit () {
      this.$refs.campFormRef.validate(async valid => {
        console.log("2123"+valid)
        if (valid) {
          console.log("2123"+this.campFormTitle === '营地审核')
          if (this.campFormTitle === '营地审核') {
            console.log("546")
            await campExamine(this.campForm)

          }
          this.campuserPage = await selectCampList(this.searchForm)
          this.campDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.campTableRef.toggleRowExpansion(row)
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCampList(this.searchForm).then(res => {
        this.campPage = res

      })
    },
    handleUpdate (index, row) {
      this.campFormTitle = '营地审核'
      this.campDialogVisible = true
      this.selectCampInfoById(row.id)
    },
    campExamine (index, row) {
      this.campFormTitle = '营地审核'
      this.campDialogVisible = true

      this.selectCampInfoById(row.id);
      
    },
    selectCampInfoById (id) {
      selectCampInfo(id).then(res => {
        this.campForm.id = res.id
        this.campForm.name = res.name
        this.campForm.type = res.type
      })
    },

    campTreeNodeClick (data) {
      this.searchForm.status = data.status
      this.searchForm.pageNum = 1
      selectCampList(this.searchForm).then(res => {
        this.campPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCampList(this.searchForm).then(res => {
        this.campPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCampList(this.searchForm).then(res => {
        this.campPage = res
        
      })
  }
}

}
</script>

<style>
</style>
